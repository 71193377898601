import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
// 进度条配置项
NProgress.configure({
  showSpinner: false
})

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/auth/Login')
  },
  {
    path: '/index',
    component: () => import('../views/index/Main'),
    redirect: '/allUserManager',
    children: [
      //仪表盘介绍(all)
      {
        path: '/comp',
        component: () => import('../views/admin/comp')
      },
      {
        path: '/user',
        component: () => import('../views/admin/user')
      },
      {
        path: '/dashboard',
        component: () => import('../views/index/Dashboard')
      },
      {
        path: '/dept',
        component: () => import('../views/admin/dept')
      },
      {
        path: '/declare',
        component: () => import('../views/admin/declare')
      },
      {
        path: '/allUserManager',
        component: () => import('../views/admin/AllUserManage')
      },
      //用户管理(超级管理员)
      {
        path: '/userManage',
        component: () => import('../views/admin/UserManage')
      },

    ]
  },

]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  NProgress.start()
  const token = window.localStorage.getItem('authorization')
  //2个不用token的页面请求
  if (to.path === '/' || to.path === '/register') {
    return next()
  }
  //没有token的情况 直接返回登录页
  if (!token) return next('/')
  //属于超级管理员的功能
  if (to.path === '/userManage' || to.path === '/roleManage' || to.path === '/noticeManage') {
    axios.get('/user/checkToken').then((resp) => {
      if (resp.data.code === 200 && resp.data.data.roleId === 3) {//当前用户携带的token信息正确并且是管理员
        next()
      }
    }).catch(err => {
      this.$notify({
        title: 'Tips',
        message: err.response.data.errMsg,
        type: 'error',
        duration: 2000
      })
      localStorage.removeItem('authorization')
      return next('/index')
    })
  }
  //属于超级管理员又属于老师
  if (to.path === '/questionManage' || to.path === '/questionBankMange' || to.path === '/examManage'
    || to.path === '/addExam' || to.name === 'updateExam' || to.path === '/markManage' || to.name === 'markExam') {
    axios.get('/common/checkToken').then((resp) => {
      if (resp.data.code === 200 && resp.data.data.roleId === 3 || resp.data.data.roleId === 2) {
        next()
      }
    }).catch(err => {
      this.$notify({
        title: 'Tips',
        message: err.response.data.errMsg,
        type: 'error',
        duration: 2000
      })
      localStorage.removeItem('authorization')
      return next('/index')
    })
  }

  //超级管理员 + 学生
  if (to.path === '/myQuestionBank' || to.name === 'trainPage' || to.path === '/examOnline'
    || to.name === 'exam' || to.name === 'examResult' || to.path === '/myGrade') {
    axios.get('/common/checkToken').then((resp) => {
      if (resp.data.code === 200 && resp.data.data.roleId !== 2) {
        next()
      }
    }).catch(err => {
      this.$notify({
        title: 'Tips',
        message: err.response.data.errMsg,
        type: 'error',
        duration: 2000
      })
      localStorage.removeItem('authorization')
      return next('/index')
    })
  }
  next()
})

router.afterEach(() => {
  NProgress.done() // 结束Progress
})

export default router
