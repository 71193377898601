import request from '@/utils/request'

export default {
  login (loginUser) {
    return request({
      url: '/user/alogin',
      method: 'post',
      data: loginUser
    })
  },
  checkToken () {
    return request({
      url: '/user/checkToken',
      method: 'get'
    })
  }


}
